import React, { useEffect } from 'react';

import HeroHome from '../partials/herohome/HeroHome';
import FeaturesBlocks from '../partials/featuresblock/FeaturesBlocks';
import Testimonials from '../partials/Testimonials';
import Newsletter from '../partials/newsletter/Newsletter';

function Home(props) {
  useEffect(() => {
    document.title = "chainbitvortex"
  })

  return (

    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <HeroHome webAppOnClick={props.webAppOnClick} downloadOnClick={props.downloadOnClick} />
        <FeaturesBlocks />

        <Testimonials />
        <Newsletter />

      </main>

      {/*  Site footer */}

    </div>
  );
}

export default Home;