import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
function Header(props) {

  const [top, setTop] = useState(true);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://cryptorank.io/widget/marquee.js";
    script.async = false;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const navOnClick = () => {
    setShowMobileMenu(false);
  }
  return (
    <header className={`nav-container fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${!top && 'bg-white blur shadow-lg'}`}>
      <div id="cr-widget-marquee"
        className="nav-marquee"
        data-coins="eos,bitcoin,ethereum,tether,ripple,binance-coin,cardano,dogecoin,solana,crypto-com-cro,chainlink"
        data-theme="light"
        data-show-symbol="true"
        data-show-icon="true"
        data-show-period-change="true"
        data-period-change="24H"
        data-api-url="https://api.cryptorank.io/v0"
      >
      </div>
      {/* nav bar md */}
      <div className=" max-w-6xl mx-auto px-5 sm:px-6">
        <div className="hidden md:flex items-center justify-between h-16 md:h-20">

          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Cruip">
              <img className=" nav-logo md:max-w-none my-2 mx-auto rounded" src={require('../../images/logo-1.png').default} alt="Features bg" />
            </Link>

          </div>

          {/* Site navigation */}
          <nav className="nav-bar flex flex-grow">
            <ul className="flex flex-grow justify-start flex-wrap items-center">
              <li>
                <Link to="/about-us" className="nav-button font-medium text-gray-600 hover:text-gray-900 px-3 py-3 flex items-center transition duration-150 ease-in-out">About Us</Link>
              </li>
              <li>
                <Link to="/terms" className="nav-button font-medium text-gray-600 hover:text-gray-900 px-3 py-3 flex items-center transition duration-150 ease-in-out">Terms of Use</Link>
              </li>
              <li>
                <Link to="/privacy" className="nav-button font-medium text-gray-600 hover:text-gray-900 px-3 py-3 flex items-center transition duration-150 ease-in-out">Privacy Policy</Link>
              </li>
            </ul>
            <ul className="flex flex-grow justify-end flex-wrap items-center">
             
              <li>
                <Link onClick={props.webAppOnClick} className="download-button">
                  <span >Web App</span>

                </Link>
              </li>
         

            </ul>

          </nav>

        </div>
      </div>

      {/* nav bar sm */}
      <div className="shadow-2xl max-w-6xl mx-auto px-5 sm:px-6">
        <div className="md:hidden flex items-center justify-between h-16 md:h-20">
          <div className="flex justify-center  mr-4">
          </div>
          {/* Site branding */}
          <div className="flex justify-center ">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Cruip">
              <img className="nav-logo md:max-w-none mx-auto rounded" src={require('../../images/logo-1.png').default} width="500" height="462" alt="Features bg" />
            </Link>
          </div>
          <button onClick={() => { setShowMobileMenu(!showMobileMenu); }} class="outline-none mobile-menu-button">
            <svg
              class="w-6 h-6 text-gray-500"
              x-show="!showMenu"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>

        </div>
        {/* nav bar menu sm */}
        <div className={` ${showMobileMenu ? "mobile-menu-show" : "mobile-menu-hide"} dropdown-menu md:hidden flex mobile-menu`}>
          <ul className="mobile-menu" >
            <li><Link onClick={navOnClick} to="/" className="block text-sm px-2 py-4 hover:bg-gray-100 ">Home</Link></li>
            <li><Link onClick={navOnClick} to="/about-us" className="block text-sm px-2 py-4 hover:bg-gray-100 transition duration-300">About Us</Link></li>
            <li><Link onClick={navOnClick} to="terms" className="block text-sm px-2 py-4 hover:bg-gray-100 transition duration-300">Terms of Use</Link></li>
            <li><Link onClick={navOnClick} to="privacy" className="block text-sm px-2 py-4 hover:bg-gray-100 transition duration-300">Privacy Policy</Link></li>
        {/*     <li className="flex justify-center"><Link className="mobile-button-container flex justify-center text-sm" onClick={navOnClick} to="signup" ><button className="btn register-button-mobile">Register</button></Link></li>
            <li className="flex justify-center">
              <Link className="web-download-button-container flex justify-center text-sm"  >
                <button onClick={props.downloadOnClick} className="btn download-button-mobile">Download</button>

              </Link>
            </li>
             */}
            <li className="flex justify-center mb-4">
              <Link className="web-download-button-container flex justify-center text-sm"  >
                <button onClick={props.webAppOnClick} className="btn download-button-mobile">Web App</button>

              </Link>
            </li>
         
          </ul>
        </div>
      </div>

    </header>
  );
}

export default Header;
