import React, { useEffect } from 'react';
import './style.css';

function Terms() {
    useEffect(() => {
        document.title = "chainbitvortex - Terms"
    })
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}


            {/*  Page content */}
            <main className="flex-grow">
                {/*  Page sections */}
                <section className="relative from-gray-100 to-white">
                    <div className="text-lg mt-24 md:mt-28 max-w-6xl  mx-auto">
                        <div className="text-4xl py-24 mx-4 text-center">
                            <strong>chainbitvortex Terms of use</strong>
                        </div>
                        <div className="my-8 mx-4">
                            These chainbitvortex Terms of Use is entered into between you (hereinafter referred to as “you” or “your”) and chainbitvortex operators (as defined below). By accessing, downloading, using or clicking on “I agree” to accept any chainbitvortex Services  provided by chainbitvortex, you agree that you have read, understood and accepted all of the terms and conditions stipulated in these Terms of Use (hereinafter referred to as “these Terms”) as well as our Privacy Policy at www.chainbitvortex.com In addition, when using some features of the Services, you may be subject to specific additional terms and conditions applicable to those features.
                        </div>
                        <div className="my-8 mx-4">
                            Please read the terms carefully as they govern your use of chainbitvortex Services.
                            <strong> THESE TERMS CONTAIN IMPORTANT PROVISIONS INCLUDING AN ARBITRATION PROVISION THAT REQUIRES ALL CLAIMS TO BE RESOLVED BY WAY OF LEGALLY BINDING ARBITRATION. </strong>
                            The terms of the arbitration provision are set forth “Resolving Disputes: Forum, Arbitration, Class Action Waiver”, hereunder. As with any asset, the values of Digital Currencies (as defined below) may fluctuate significantly and there is a substantial risk of economic losses when purchasing, selling, holding or investing in Digital Currencies and their derivatives.
                            <div>
                                <strong>BY MAKING USE OF chainbitvortex SERVICES, YOU ACKNOWLEDGE AND AGREE THAT: (1) YOU ARE AWARE OF THE RISKS ASSOCIATED WITH TRANSACTIONS OF DIGITAL CURRENCIES AND THEIR DERIVATIVES; (2) YOU SHALL ASSUME ALL RISKS RELATED TO THE USE OF chainbitvortex SERVICES AND TRANSACTIONS OF DIGITAL CURRENCIES AND THEIR DERIVATIVES; AND (3) chainbitvortex SHALL NOT BE LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES.
                                </strong>
                            </div>
                        </div>
                        <div className="my-8 mx-4 subtitle">
                            By accessing, using or attempting to use chainbitvortex Services in any capacity, you acknowledge that you accept and agree to be bound by these Terms. If you do not agree, do not access chainbitvortex or utilize chainbitvortex services.
                        </div>
                        <div className="my-8 mx-4 subtitle">
                            All Users must apply for a chainbitvortex Account at (https://www.chainbitvortex.com) before using chainbitvortex Services. When you register a chainbitvortex Account, you must provide your real name, email address and password, and accept these Terms, the Privacy Policy, and other chainbitvortex Platform Rules. chainbitvortex may refuse, in its discretion, to open a chainbitvortex Account for you. You agree to provide complete and accurate information when opening a chainbitvortex Account and agree to timely update any information you provide to chainbitvortex to maintain the integrity and accuracy of the information. Only one User can be registered at a time, but each individual User (including any User that is a business or legal entity) may maintain only one main account at any given time. Institutional Users (including Users that are businesses and other legal entities) can open one or more subaccounts under the main account with the consent of chainbitvortex. For certain chainbitvortex Services, you may be required to set up a special account independent from your chainbitvortex Account, based on the provisions of these Terms or the Supplementary Terms. The registration, use, protection and management of such trading accounts are equally governed by the provisions of this article.
                        </div>
                        <div className="my-8 mx-4 text-2xl">Identity Verification</div>
                        <div className="my-8 mx-4 subtitle">
                            After registration for your chainbitvortex Account, you agree to provide us with the information we request for the purposes of identity verification and the detection of money laundering, terrorist financing, fraud, or any other financial crimes and permit us to keep a record of such information. You will need to complete certain verification procedures before you are permitted to use the chainbitvortex Services. Your access to one or more chainbitvortex Services and the limits that apply to your use of the chainbitvortex Services, may be altered as a result of information collected about what you going on.

                        </div>
                        <div className="my-8 mx-4 subtitle">
                            The information we request may include certain personal information, including, but not limited to, your name, address, e-mail address, date of birth, government identification, special categories of personal data, such as your biometric information. In providing us with this or any other information that may be required, you confirm that the information is accurate and authentic. You agree to keep us updated if any of the information you provide changes. You authorize us to make inquiries, whether directly or through third parties, that we consider necessary to verify your identity or protect you and/or us against fraud or other financial crime, and to take action we reasonably deem necessary based on the results of such inquiries.
                        </div>
                        <div className="my-8 mx-4 mb-24 subtitle">
                            When we carry out these inquiries, you acknowledge and agree that your personal information may be disclosed to credit reference and fraud prevention or financial crime agencies and that these agencies may respond to our inquiries in full. This is an identity check only and should have no adverse effect on your credit rating. Further, you authorize your wireless operator to use your mobile number, name, address, email, network status, customer type, customer role, billing type, mobile device identifiers (IMSI and IMEI) and other subscriber status details, if available, solely to allow verification of your identity and to compare information you have provided to chainbitvortex with your wireless operator account profile information for the duration of the business relationship.

                        </div>
                    </div>
                </section>
            </main>

            {/*  Site footer */}

        </div>
    )
}
export default Terms;