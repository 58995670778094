import React, { useState } from 'react';
import Modal from '../../utils/Modal';
import { Link } from 'react-router-dom';
import './style.css';
function HeroHome(props) {


  return (
    <section className="relative herohome-container">

      {/* Illustration behind hero content */}
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

          {/* Section header */}
          <div className="text-center pb-12 md:pb-16">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Welcome to <span className="linear-highlight-text">chainbitvortex</span></h1>
            <div className="max-w-3xl mx-auto">
              <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">

                Create your chainbitvortex portfolio Today!
                Secure your Cryptocurrency to experience the best platform of the Crypto community. everyday!
              </p>
              <div>
                <img className="md:max-w-none mx-auto rounded" src={require('../../images/app-screenshot.png').default} width="500" height="462" alt="Features bg" />
              </div>
          {/*     <div className="button-container mx-auto  flex" data-aos="zoom-y-out" data-aos-delay="300">
                <div onClick={props.downloadOnClick} className="w-1/2 pr-2 mt-3" >
                  <button className="btn webBtn w-full text-white " >Download</button>
                </div>
                <Link className="w-1/2 pl-2 mt-3" to="/signup">
                  <button className="btn w-full text-white bg-gray-900 hover:bg-gray-800  " href="/signup">Register</button>
                </Link>
              </div> */}
              <div className="button-container mx-auto " data-aos="zoom-y-out" data-aos-delay="300">
                <div onClick={props.webAppOnClick} className="w-full mt-3">
                  <button className="btn  text-white webBtn  w-full mb-4 sm:mb-0" >Web app</button>
                </div>

              </div>
            </div>
          </div>

          {/* Hero image */}


        </div>

      </div>
    </section >
  );
}

export default HeroHome;