import React, { useEffect } from 'react';

import './style.css';

function Privacy() {
    useEffect(() => {
        document.title = "chainbitvortex - Privacy"
    })
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

        {/*  Page content */}
        <main className="flex-grow">
            {/*  Page sections */}
            <section className="relative from-gray-100 to-white">
                <div className="text-lg mt-24 md:mt-28 max-w-6xl  mx-auto">
                    <div className="text-4xl py-24 mx-4 text-center">
                        <strong>chainbitvortex Privacy policy</strong>
                    </div>
                    <div className="py-8 mx-8">
                        By accessing and using our Services, you already acceptance to the terms of this Privacy Policy. Where we require your consent to process your personal information, we will ask for your consent to the collection, use, and disclosure of your personal information as described further below. We may provide information about the data processing practices of specific Services. These notices may supplement or clarify our privacy practices or may provide you with additional choices about how we process your data.
                    </div>
                    <div className="py-8 mx-8">
                        Personal information is typically data that identifies an individual or relates to an identifiable individual. This includes information you provide to us, information which is collected about you automatically, and information we obtain from third parties. The definition of personal information depends on the applicable law based on your physical location. Only the definition that applies to your physical location will apply to you under this Privacy Policy.
                    </div>
                    <div className="py-8 mx-8">
                        All the information you provide to us. To establish an account and access our Services, we'll ask you to provide us with some important information about yourself. This information is either required by law (e.g. to verify your identity), necessary to provide the requested services (e.g. you will need to provide your bank account number if you'd like to link that account to CB), or is relevant for certain specified purposes, described in greater detail below. As we add new features and Services, you may be asked to provide additional information.
                    </div>
                    <div className="py-8 mx-8">
                        If you choose not to share certain information with us, we may not be able to serve you as effectively or offer you our Services. Any information you provide to us that is not required is voluntary.
                        <br />
                        We may collect the following types of information from you:
                        <ol className="privacy-list ">
                            <li className="privacy-list py-2">
                                Personal identification information: Full name, date of birth, nationality, gender, utility bills, photographs, phone number, home address, or email.
                            </li>
                            <li className="privacy-list py-2">
                                Formal Identification information: Government issued identity document such as Passport, Driving License, National Identity Card, Tax number, passport number, driving license details, identity card details, visa information, or any other information deemed necessary to comply with our legal obligations under financial or anti- money laundering laws
                            </li>
                            <li className="privacy-list py-2">
                                Transaction Information: Information about the transactions you make on our Services, such as the name of the recipient, your name, the amount, and/or timestamp.
                            </li>
                            <li className="privacy-list py-2 ">
                                Audio, electronic, visual and similar information, such as call and video recordings.
                            </li>
                            <li className="privacy-list py-2">
                                Correspondence: Survey responses, information provided to our support team or user research team.
                            </li>
                        </ol>
                    </div>
                    <div className="my-8 mx-4 text-2xl">Legal Bases for the personal information</div>
                    <div className="py-8 mx-8">For individuals who are located in the European Economic Area, the United Kingdom or Switzerland (collectively “EEA Residents'') at the time their personal information is collected, our legal bases for processing your information under the EU General Data Protection Regulation (“GDPR”) will depend on the personal information at issue, the specific context in the which the personal information is collected and the purposes for which it is used. We generally only process your data where we are legally required to, where processing is necessary to perform any contracts we entered with you (or to take steps at your request prior to entering into a contract with you), where processing is in our legitimate interests to operate our business and not overridden by your data protection interests or fundamental rights and freedoms, or where we have obtained your consent to do so. In some rare instances, we may need to process your personal information to protect your vital interests or those of another person. If you have questions about or need further information concerning the legal basis on which we collect and use your personal information, please contact us using the contact details provided under the "How to contact us" heading below.</div>
                    <div className="py-8 mx-8">
                        Depending on applicable law where you reside, you may be able to assert certain rights related to your personal information identified below. If any of the rights listed below are not provided under law for your operating entity or jurisdiction, CB has absolute discretion in providing you with those rights.
                        Your rights to personal information are not absolute. Depending upon the applicable law, access to your rights under the applicable law may be denied: (a) when denial of access is required or authorized by law; (b) when granting access would have a negative impact on another's privacy; (c) to protect our rights and properties; (d) where the request is frivolous or vexatious, or for other reasons.

                        <ol className="privacy-list ">
                            <li className="privacy-list py-2">
                                Access and portability. You may request that we provide you a copy of your personal information held by us. This information will be provided without undue delay subject to a potential fee associated with gathering of the information (as permitted by law), unless such provision adversely affects the rights and freedoms of others. In certain circumstances, you may request to receive your personal information in a structured, commonly used and machine-readable format, and to have us transfer your personal information directly to another data controller.
                            </li>
                            <li className="privacy-list py-2">
                                Erasure. You may request to erase your personal information, subject to applicable law. If you close your CB Account, we will mark your account in our database as "Closed," but will keep certain account information, including your request to erase, in our database for a period of time as described above. This is necessary to deter fraud, by ensuring that persons who try to commit fraud will not be able to avoid detection simply by closing their account and opening a new account, and to comply with CB's legal obligations. However, if you close your account, your personal information will not be used by us for any further purposes, nor shared with third parties, except as necessary to prevent fraud and assist law enforcement, as required by law, or in accordance with this Privacy Policy.
                            </li>
                            <li className="privacy-list py-2 mb-24">
                                Withdraw consent. To the extent the processing of your personal information is based on your consent, you may withdraw your consent at any time. Your withdrawal will not affect the lawfulness of CB's processing based on consent before your withdrawal.
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
        </main>
    </div>
    )
}
export default Privacy;