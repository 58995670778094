
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './style.css';

function SignUp() {
  //var
  const [tab, setTab] = useState(0);
  const [isInputEmpty1, setIsInputEmpty1] = useState(true);
  const [isInputEmpty2, setIsInputEmpty2] = useState(true);
  const [nextClicked, setNextClicked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");
  const [userData, setUserData] = useState({
    "username": "",
    "email": "",
    "firstName": "",
    "lastName": "",
    "password": "",
    "confirmPassword": "",
    "passport": "",
    "passportImage": "",
    "uploadPassportLater": false,
    "referralCode": ""
  });
  const [userDataInvalid, setUserDataInvalid] = useState({
    "username": "",
    "email": "",
    "firstName": "",
    "lastName": "",
    "password": "",
    "confirmPassword": "",
    "passport": "",
    "passportImage": ""
  })
  //effect
  useEffect(() => {
    document.title = "chainbitvortex - Sign up"
  })
  useEffect(() => {
    if (userData.username != "" && userData.email != "" && userData.password != "" && userData.confirmPassword != "") {
      setIsInputEmpty1(false);
    } else {
      setIsInputEmpty1(true);
    }
  }, [userData.username, userData.email, userData.password, userData.confirmPassword])
  useEffect(() => {
    if (userData.firstName != "" && userData.lastName != "" && (userData.uploadPassportLater || (userData.passport != "" && userData.passportImage != ""))) {
      setIsInputEmpty2(false);
    } else {
      setIsInputEmpty2(true);
    }
  }, [userData.uploadPassportLater, userData.passport, userData.passportImage, userData.firstName, userData.lastName])
  //function
  const handleUserInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setUserData({ ...userData, [id]: value });
    console.log(userData)
  }
  const handleCheckInput = (e) => {
    const id = e.target.id;
    const value = e.target.checked;
    setUserData({ ...userData, [id]: value });
  }
  const handleFileInput = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log(11)
      let img = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURI = e.target.result;
        console.log(dataURI)
        if (dataURI) {
          setUserData({ ...userData, "passportImage": dataURI });
        }
      };
      reader.readAsDataURL(img);

    }
  }
  const nextStep = () => {
    var userInvalid = {};
    if (userData.username.length < 5) {
      userInvalid.username = "Too short username"
    } else {
      userInvalid.username = false;
    }
    if (!/.+@.+\..+/.test(userData.email)) {
      userInvalid.email = "Wrong email format"
    } else {
      userInvalid.email = false;
    }
    if (userData.password.length < 8) {
      userInvalid.password = "Please use at least 8 characters"
    } else {
      userInvalid.password = false;
    }
    if (userData.confirmPassword != userData.password) {
      userInvalid.confirmPassword = "Those passwords didn’t match."
    } else {
      userInvalid.confirmPassword = false;
    }
    setUserDataInvalid({ ...userDataInvalid, ...userInvalid });
    if (!userInvalid.username && !userInvalid.email && !userInvalid.password && !userInvalid.confirmPassword) {
      setTab(1);
      setNextClicked(false);
    }
  }
  const prevStep = () => {
    setTab(0)
  }
  const signUp = () => {
    setIsLoading(true)
    const url = "https://api.chainbitvortex.com/";
    axios({
      method: "POST",
      url: url + "auth/userRegister",
      data: {
        Username: userData.username,
        Email: userData.email,
        Password: userData.password,
        FirstName: userData.firstName,
        LastName: userData.lastName,
        Passport: userData.uploadPassportLater ? "" : userData.passport,
        PassportImage: userData.uploadPassportLater ? "" : userData.passportImage,
        ReferralCode: userData.referralCode,
      },
    }).then(
      (response) => {
        setIsLoading(false);
        if (response.data.status) {
          setSuccess(true);
        } else {
          if (response.data.message == "emailUsed") {
            setReturnMessage("The email has been used");
          } else if (response.data.message == "usernameUsed") {
            setReturnMessage("The username has been used");
          } else if (response.data.message == "refererNotFound") {
            setReturnMessage("Invalid referral code");
          } else {
            setReturnMessage("Unknown error");
          }
        }
      },
      (error) => {
        setIsLoading(false);
        setReturnMessage("Unknown error");
        console.log(returnMessage);
        console.log(error);
      }
    );
  }
  const closeAlert = (e) => {
    setReturnMessage("");
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      <main className="flex-grow">

        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div>
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                  <h1 className="h1">Join chainbitvortex
                  </h1>
                  <h1 className="h2">
                    Create Your Account</h1>
                </div>
                {!success ? (
                  <div className=" max-w-sm mx-auto overflow-hidden">
                    <div className="slide-container overflow-hidden">
                      <div className={`${tab == 0 ? nextClicked ? "" : "slide1-show" : "slide1-hide"} slide1 max-w-sm`}>
                        {/*  Form 1 */}
                        <form>
                          {/*  Username */}
                          <div className="flex flex-wrap -mx-3 mb-4">
                            <div className="w-full px-3">
                              <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email">Username <span className="text-red-600">*</span></label>
                              <input
                                onChange={handleUserInput}
                                value={userData.username}
                                id="username" type="text"
                                className="form-input w-full text-gray-800"
                                placeholder="Username"
                              />
                              {userDataInvalid.username ? (
                                <div className="my-1 flex flex-nowrap items-center">
                                  <img className="mx-1  alert-logo" src={require('../../images/exclamation-mark.png').default} />
                                  <div className=" alert-text">
                                    {userDataInvalid.username}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {/*  Email */}
                          <div className="flex flex-wrap -mx-3 mb-4">
                            <div className="w-full px-3">
                              <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></label>
                              <input
                                onChange={handleUserInput}
                                value={userData.email}
                                id="email"
                                type="email"
                                className="form-input w-full text-gray-800"
                                placeholder="Email Address" />
                              {userDataInvalid.email ? (
                                <div className="my-1 flex flex-nowrap items-center">
                                  <img alt="!" className="mx-1  alert-logo" src={require('../../images/exclamation-mark.png').default} />
                                  <div className=" alert-text">
                                    {userDataInvalid.email}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {/*  Password */}
                          <div className="flex flex-wrap -mx-3 mb-4">
                            <div className="w-full px-3">
                              <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="password">Password <span className="text-red-600">*</span></label>
                              <input
                                onChange={handleUserInput}
                                value={setUserData.password}
                                id="password"
                                type="password"
                                className="form-input w-full text-gray-800"
                                placeholder="Password" />
                              {userDataInvalid.password ? (
                                <div className="my-1 flex flex-nowrap items-center">
                                  <img alt="!" className="mx-1  alert-logo" src={require('../../images/exclamation-mark.png').default} />
                                  <div className=" alert-text">
                                    {userDataInvalid.password}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {/*  Confirm */}
                          <div className="flex flex-wrap -mx-3 mb-4">
                            <div className="w-full px-3">
                              <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="password">Confirm password <span className="text-red-600">*</span></label>
                              <input
                                onChange={handleUserInput}
                                value={userData.confirmPassword}
                                id="confirmPassword"
                                type="password"
                                className="form-input w-full text-gray-800"
                                placeholder="Confirm password" />
                              {userDataInvalid.confirmPassword ? (
                                <div className="my-1 flex flex-nowrap items-center">
                                  <img alt="!" className="mx-1  alert-logo" src={require('../../images/exclamation-mark.png').default} />
                                  <div className=" alert-text">
                                    {userDataInvalid.confirmPassword}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="flex flex-wrap -mx-3 mt-6">
                            <div className="w-full px-3">

                              <button type="button" disable={isInputEmpty1} onClick={nextStep} className={`${isInputEmpty1 ? "button-disabled" : ""} btn text-white nextBtn w-full`}>Next</button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className={`${tab == 1 ? "slide2-show" : nextClicked ? "" : tab == 3 ? "slide1-hide" : "slide2-hide"} slide2 max-w-sm`}>
                        {/*  Form 2 */}
                        <form>
                          <div className="flex flex-wrap -mx-3 mb-4">
                            <div className=" w-full px-3">
                              <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email">Referral code (optional) <span className="text-red-600"></span></label>
                              <input
                                onChange={handleUserInput}
                                value={userData.referralCode}
                                id="referralCode" type="text"
                                className="form-input w-full text-gray-800"
                                placeholder="Referral Code"
                                disabled={isLoading} />
                            </div>
                          </div>
                          {/*  Name */}
                          <div className="flex flex-wrap -mx-3">
                            <div className="md:grid md:grid-cols-2  w-full">
                              <div className=" w-full px-3 mb-4">
                                <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="name">FirstName <span className="text-red-600">*</span></label>
                                <input
                                  onChange={handleUserInput}
                                  value={userData.firstName}
                                  id="firstName"
                                  type="text"
                                  className="form-input w-full text-gray-800"
                                  placeholder="Firstname"
                                  disabled={isLoading} />
                              </div>
                              <div className=" w-full px-3 mb-4">
                                <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="name">LastName <span className="text-red-600">*</span></label>
                                <input
                                  onChange={handleUserInput}
                                  value={userData.lastName}
                                  id="lastName"
                                  type="text"
                                  className="form-input w-full text-gray-800"
                                  placeholder="Lastname"
                                  disabled={isLoading} />
                              </div>
                            </div>
                          </div>
                          {/*  Passport */}
                          {userData.uploadPassportLater ? null : (
                            <div>
                              <div className="flex flex-wrap -mx-3 mb-4">
                                <div className=" w-full px-3">
                                  <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email">Passport <span className="text-red-600">*</span></label>
                                  <input
                                    onChange={handleUserInput}
                                    value={userData.passport}
                                    id="passport" type="text"
                                    className="form-input w-full text-gray-800"
                                    placeholder="Passport"
                                    disabled={isLoading} />
                                </div>
                              </div>
                              <div className="flex flex-wrap -mx-3 mb-4">
                                <div className="w-full px-3">
                                  <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email">Identity document upload <span className="text-red-600">*</span></label>
                                  <div className="h-auto  form-input w-full text-gray-800">
                                    <label className=" inline-block w-full">
                                      <input
                                        onChange={handleFileInput}
                                        id="passportImage" type="file"
                                        accept="image/png, image/jpg, image/jpeg"
                                        placeholder="Passport"
                                        disabled={isLoading} />
                                      {userData.passportImage ? (
                                        <img className="mx-auto" alt="passport" src={userData.passportImage}></img>
                                      ) : (
                                        <div className="text-center"> Click me to upload</div>
                                      )}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}


                          <div className="flex flex-wrap -mx-3 mb-4">
                            <div className=" w-full px-3">
                              <label className="my-4">
                                <input onChange={handleCheckInput}
                                  value={setUserData.uploadPassportLater}
                                  id="uploadPassportLater"
                                  type="checkbox"
                                  disabled={isLoading} />
                                <label className="mx-2">Submit the passport later</label>
                              </label>
                            </div>
                          </div>


                          {returnMessage ? (<div className="flex flex-wrap -mx-3">
                            <div className=" w-full px-3">
                              <div class="alert">
                                <span class="closebtn" onClick={closeAlert} >&times;</span>
                                {returnMessage}
                              </div>
                            </div>
                          </div>) : null}

                          <div className="flex flex-wrap -mx-3 mt-6">
                            <div className="grid grid-cols-2 w-full">
                              <div className="w-full px-3 mb-4">
                                <button type="button" onClick={prevStep} disable={isLoading} className={`${isLoading ? "button-disabled" : ""} w-full btn text-white nextBtn`}>Back</button>
                              </div>
                              <div className=" w-full px-3 mb-4">
                                <button type="button" onClick={signUp} disable={isInputEmpty2} className={`${isInputEmpty2 ? "button-disabled" : ""} btn text-white nextBtn w-full h-full `}>{isLoading ? (<i className="mx-2 fa fa-refresh fa-spin"></i>) : "Sign up"}</button>
                              </div>
                            </div>
                          </div>
                          <div className="text-sm text-gray-500 text-center mt-3">
                            By creating an account, you agree to the <Link to="/terms"><a className="underline" href="#0">terms & conditions</a></Link>, and our <Link to="/privacy"><a className="underline" href="#0">privacy policy</a></Link>.
                          </div>
                        </form>

                      </div>
                    </div>
                  </div>) : (
                  <div className=" max-w-sm mx-auto overflow-hidden">
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <div className="my-2 text-2xl text-center">Success</div>
                        <img className="my-2 w-1/5 mx-auto" alt="check " src={require('../../images/check.png').default} />
                        <div className="my-2 text-lg text-center">your account has been created.</div>
                        <div className="my-2 text-lg text-center">Please verify your email.</div>
                      </div>
                    </div>
                  </div>)}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div >
  );
}

export default SignUp;