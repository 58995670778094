import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

function Footer() {
  return (
    <footer>

      <div className="md:flex hidden max-w-6xl mx-auto px-4 sm:px-6">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          {/* Bottom area */}
          <div className="md:flex hidden md:items-center md:justify-between py-4 md:py-8 ">
            <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
              <li className="ml-4">
                <Link to="/" className="flex justify-center items-center text-gray-400 hover:text-gray-200 transition duration-150 ease-in-out" >
                  Home
                </Link>
              </li>
              <li className="ml-4">
                <Link to="/about-us"  className="flex justify-center items-center text-gray-400 hover:text-gray-200 transition duration-150 ease-in-out" >
                  About Us
                </Link>
              </li>
              <li className="ml-4">
                <Link to="/terms"   className="flex justify-center items-center text-gray-400 hover:text-gray-200  transition duration-150 ease-in-out" >
                  Terms of Use
                </Link>
              </li>
              <li className="ml-4">
                <Link to="/privacy"   className="flex justify-center items-center text-gray-400 hover:text-gray-200 transition duration-150 ease-in-out">
                  Privacy Policy
                </Link>
              </li>
            </ul>

            {/* Copyrights note */}
            <div className="text-sm text-gray-400 mr-4">chainbitvortex 2018. All rights reserved.</div>

          </div>
          <div className="mb-4 flex justify-center items-center">
            <ul className="flex md:order-1 md:ml-4 md:mb-0">
              <li className="ml-6">
                <img className="h-10" src={require('../../images/cert3.555f0049.webp').default}></img>
              </li>
              <li className="ml-6">
                <img className="h-10" src={require('../../images/cert4.d713395c.webp').default}></img>
              </li>
              <li className="ml-6">
                <img className="h-10" src={require('../../images/cert1.8d97b72c.webp').default}></img>
              </li>
              <li className="ml-6">
                <img className="h-10" src={require('../../images/cert2.36a2ca91.webp').default}></img>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-4 flex md:hidden max-w-6xl mx-auto px-4 sm:px-6">
        <ul className="w-full justify-center items-center">
          <li className="mt-2">
            <Link to="/" className="flex justify-center items-center text-gray-400 hover:text-gray-200 transition duration-150 ease-in-out" >
              Home
            </Link>
          </li>
          <li className="mt-2">
            <Link to="/about-us" className="flex justify-center items-center text-gray-400 hover:text-gray-200 transition duration-150 ease-in-out" >
              About Us
            </Link>
          </li>
          <li className="mt-2">
            <Link to="/terms" className="flex justify-center items-center text-gray-400 hover:text-gray-200  transition duration-150 ease-in-out" >
              Terms of Use
            </Link>
          </li>
          <li className="mt-2">
            <Link to="/privacy" className="flex justify-center items-center text-gray-400 hover:text-gray-200 transition duration-150 ease-in-out">
              Privacy Policy
            </Link>
          </li>
          <li className="mt-4">
            <div className="flex justify-center items-center text-sm text-gray-400">chainbitvortex 2018. All rights reserved.</div>
          </li>
          <li className="mt-3 flex justify-center items-center">
            <img className="h-10" src={require('../../images/cert3.555f0049.webp').default}></img>
          </li>
          <li className="mt-3 flex justify-center items-center">
            <img className="h-10" src={require('../../images/cert4.d713395c.webp').default}></img>
          </li>
          <li className="mt-3 flex justify-center items-center">
            <img className="h-10" src={require('../../images/cert1.8d97b72c.webp').default}></img>
          </li>
          <li className="my-3 flex justify-center items-center">
            <img className="h-10" src={require('../../images/cert2.36a2ca91.webp').default}></img>
          </li>

        </ul>
      </div>
    </footer>
  );
}

export default Footer;
